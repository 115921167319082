.breadcrumb {
  &__area {
    padding-top: 300px;
    padding-bottom: 192px;
    // background-image: url(../imgs/hero/breadcrumb.png);
    background-image: url("~/public/assets/imgs/hero/breadcrumb.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    @media #{$lg} {
      padding-top: 220px;
      padding-bottom: 150px;
    }

    @media #{$sm} {
      padding-top: 160px;
      padding-bottom: 80px;
    }
  }

  &__inner {
    gap: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media #{$sm} {
      flex-direction: column;
    }
  }

  &__right {
    li {
      display: inline-block;
      padding-right: 40px;
      position: relative;

      &::after {
        position: absolute;
        content: "\f105";
        font-family: "Font Awesome 6 Free";
        font-weight: 900;
        right: 15px;
        top: 3px;
        color: var(--secondary);
      }

      &:last-child {
        padding-right: 0;

        &::after {
          display: none;
        }
      }

      a {
        font-weight: 600;
        font-size: 20px;
        line-height: 1.3;
        color: var(--secondary);
        text-transform: capitalize;

        @media #{$sm} {
          font-size: 18px;
        }

        &:hover {
          color: var(--primary);
        }
      }
    }
  }

  &__title {
    font-weight: 600;
    font-size: 48px;
    line-height: 1;
    letter-spacing: 0.16px;
    color: var(--secondary);
    text-transform: uppercase;

    @media #{$sm} {
      font-size: 36px;
    }
  }
}