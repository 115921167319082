/*

-----------------------
HOME FIVE HERO 
-----------------------

*/

.kraft64-five {
  background: #0e1e2a;
}

.hero {
  &__area {
    &-5 {
      // background-image: url('../imgs/home-5/hero.jpg');
      background-image: url("~/public/assets/imgs/home-5/hero.jpg");
      background-repeat: no-repeat;
      padding-top: 240px;
      padding-bottom: 150px;
      position: relative;
      @media #{$sm} {
        padding-top: 160px;
      }
      &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        opacity: 0.5;
        background: #0e1e2a;
      }
    }
  }
  &__content {
    &-5 {
      position: relative;
      z-index: 2;
    }
  }
  &__title {
    &-5 {
      color: var(--white);
      font-size: 100px;
      font-weight: 700;
      line-height: 1.2;
      letter-spacing: -3px;
      text-transform: uppercase;
      @media #{$lg} {
        font-size: 70px;
      }
      @media #{$sm} {
        font-size: 40px;
      }
    }
  }
  &__dis {
    &-5 {
      color: var(--white);
      font-family: $font_barlow;
      font-size: 16px;
      font-weight: 500;
      line-height: 1.5;
    }
  }
  &__btn-wrapper {
    &-5 {
      padding-top: 70px;
      .db-btn-arrow {
        color: var(--white);
        &::after {
          background: var(--primary);
        }
      }
    }
  }
  &__shape-wrapper {
    position: absolute;
    right: 0;
    bottom: 0;
  }
}

/*

-----------------------
HOME FIVE SERVICE 
-----------------------

*/
.kraft64-section-wrapper-5 {
  margin-bottom: 70px;
  position: relative;
}
.section-subtitle-5 {
  color: var(--primary);
  font-size: 18px;
  font-weight: 500;
  line-height: 1.2;
  letter-spacing: 1.8px;
  text-transform: uppercase;
  margin-bottom: 10px;
}
.section-title-5 {
  color: var(--white);
  font-size: 48px;
  font-weight: 600;
  line-height: 1;
  letter-spacing: 0.16px;
  text-transform: uppercase;
  @media #{$md} {
    font-size: 36px;
  }
}
.hero__btn-wrapper-5.service-btn {
  padding-top: 40px;
}
.service {
  &__area {
    &-5 {
      padding-top: 150px;
      padding-bottom: 150px;
      @media #{$md} {
        padding-top: 60px;
        padding-bottom: 60px;
      }
    }
  }
  &__items {
    &-5 {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 50px;
      @media #{$lg} {
        gap: 0;
      }
      @media #{$md} {
        grid-template-columns: repeat(2, 1fr);
      }
      @media #{$sm} {
        grid-template-columns: 1fr;
      }
    }
  }
  &__item {
    &-5 {
      text-align: center;
      position: relative;
      transition: 0.6s;
      padding: 40px;
      &:hover {
        background: #25333f;
      }
    }
  }
  &__thumb {
    &-5 {
      margin-bottom: 30px;
    }
  }
  &__content {
    &-5 {
      p {
        color: var(--white);
        font-size: 16px;
        font-weight: 500;
        line-height: 1.5;
      }
    }
  }
  &__item-title {
    color: var(--white);
    font-size: 20px;
    font-weight: 600;
    line-height: 1.2;
    margin-bottom: 20px;
    &:hover {
      color: var(--primary-3);
    }
  }
}

/*

-----------------------
HOME FIVE HISTORY 
-----------------------

*/

.history {
  &__area {
    &-5 {
      padding-bottom: 150px;
      @media #{$md} {
        padding-bottom: 60px;
      }
    }
  }
  &__items {
    &-5 {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 30px;
      @media #{$sm} {
        grid-template-columns: 1fr;
      }
    }
  }
  &__item {
    &-5 {
      max-width: 284px;
      margin-left: 45px;
      .double-chevron {
        transform: translate(-35px, 25px);
      }
      .title {
        color: var(--white);
        font-size: 48px;
        font-weight: 600;
        line-height: 1;
        letter-spacing: 0.16px;
        text-transform: uppercase;
        margin-bottom: 20px;
        @media #{$sm} {
          font-size: 30px;
        }
      }
      .title-2 {
        color: var(--white);
        font-size: 20px;
        font-weight: 600;
        line-height: 1.2;
        margin-bottom: 10px;
      }
      p {
        color: var(--white);
      }
    }
  }
  &__thumb-5 {
    position: relative;
    transform: translateX(100px);
    @media #{$lg} {
      transform: translateX(0);
    }
    .history-shape-5 {
      position: absolute;
      right: -110px;
      bottom: -105px;
      @media #{$sm} {
        display: none;
      }
    }
  }
  &__circle-btn-wrapper {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
  }
  &__circle-btn {
    background: var(--yellow-2);
    width: 152px;
    height: 152px;
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    @media #{$sm} {
      height: 100px;
      width: 100px;
    }
    .title {
      color: var(--secondary);
      font-size: 48px;
      font-weight: 600;
      line-height: 1;
      letter-spacing: 0.16px;
      text-transform: uppercase;
      @media #{$sm} {
        font-size: 30px;
      }
    }
  }
  &__box-wrapper {
    position: absolute;
    bottom: -70px;
    right: 0;
    z-index: 3;
    @media #{$md} {
      bottom: -18px;
    }
  }
  &__box-5 {
    padding: 30px 60px;
    background: var(--primary);
    max-width: 370px;
    @media #{$sm} {
      padding: 10px 40px;
    }
    .title {
      color: #252525;
      font-size: 48px;
      font-weight: 600;
      line-height: 1;
      letter-spacing: 0.16px;
      text-transform: uppercase;
      @media #{$sm} {
        font-size: 30px;
      }
    }
    .title-2 {
      color: var(--p-3, #252525);
      font-size: 100px;
      font-weight: 700;
      line-height: 1;
      letter-spacing: -3px;
      text-transform: uppercase;
      padding-left: 70px;
      @media #{$lg} {
        font-size: 50px;
      }
    }
  }
}

/*

-----------------------
HOME FIVE GALLARY 
-----------------------

*/
.gallary__section-wrapper {
  margin-bottom: 70px;
}
.gallary__sec-title {
  color: var(--white);
  font-size: 48px;
  font-weight: 600;
  line-height: 1;
  letter-spacing: 0.16px;
  text-transform: uppercase;
  @media #{$sm} {
    font-size: 25px;
  }
  small {
    color: var(--white);
    font-size: 100px;
    font-weight: 700;
    line-height: 1;
    letter-spacing: -3px;
    text-transform: uppercase;
    @media #{$lg} {
      font-size: 50px;
    }
    @media #{$sm} {
      font-size: 35px;
    }
  }
}
.gallary {
  &__area {
    &-5 {
      padding-bottom: 150px;
      position: relative;
      .gallary__circle-5 {
        position: absolute;
        right: 290px;
        top: 0;
        @media #{$lg} {
          right: 30px;
        }
        @media #{$md} {
          display: none;
        }
      }
      @media #{$sm} {
        padding-bottom: 80px;
      }
      .grid {
        gap: 30px;
      }
      .portfolio {
        position: relative;
        margin-right: 10px;
        @media #{$lg} {
          margin-bottom: 10px;
        }
        &:last-child {
          margin-right: 0;
        }
        &::before {
          position: absolute;
          content: "";
          left: 30px;
          right: 30px;
          bottom: 30px;
          top: 30px;
          background: #252525;
          opacity: 0;
          visibility: hidden;
          transition: 0.6s;
        }
        &:hover {
          &::before {
            opacity: 0.5;
            visibility: visible;
          }
        }
      }
      .portfolio__text {
        position: absolute;
        bottom: 70px;
        left: 60px;
        color: var(--white);
      }
      .portfolio-title {
        color: var(--white);
        font-size: 20px;
        font-weight: 600;
        line-height: 1.1;
      }
      span {
        color: var(--gray-200, #e1e1e1);
        font-size: 16px;
        font-weight: 500;
        line-height: 1;
      }
      .portfolio__icon {
        position: absolute;
        width: 40px;
        height: 40px;
        background: var(--primary);
        display: flex;
        align-items: center;
        justify-content: center;
        bottom: 67px;
        right: 70px;
      }
      .portfolio__menu {
        margin-bottom: 70px;
        button {
          padding: 10px 20px;
          border: 0;
          background: var(--white);
          @media #{$sm} {
            margin: 5px;
          }
          &.active {
            background: var(--yellow-2);
          }
        }
      }
    }
  }
}

/*

-----------------------
HOME FIVE NEWSLETTER 
-----------------------

*/

.newsletter {
  &__area {
    &-5 {
      background: var(--primary);
      padding: 40px;
    }
  }
  &__title-5 {
    color: #0e1e2a;
    font-size: 48px;
    font-weight: 600;
    line-height: 1;
    letter-spacing: 0.16px;
    text-transform: uppercase;
    @media #{$sm} {
      font-size: 30px;
    }
  }
  &__form-wrapper-5 {
    position: relative;
    @media #{$md} {
      margin-top: 30px;
    }
    form {
      input {
        height: 65px;
        padding: 30px;
        background: #0e1e2a;
        color: var(--white);
        max-width: 460px;
        width: 100%;
        margin-bottom: 20px;
        border: 0;
        &:focus {
          outline: 0;
        }
        &::placeholder {
          color: var(--white);
          font-size: 16px;
          font-weight: 500;
          line-height: 1;
        }
      }
      button {
        border: 0;
        padding: 20px 30px;
        background: #252525;
        color: var(--white);
      }
    }
  }
}

/*

-----------------------
HOME FIVE TEAM 
-----------------------

*/

.team {
  &__area {
    &-5 {
      padding-left: 100px;
      padding-right: 100px;
      @media #{$md} {
        padding-left: 50px;
        padding-right: 50px;
      }
      .team__member {
        &:hover {
          .team__content {
            background-color: #26343f;
          }
        }
      }
      .team-active-5 {
        .swiper-wrapper {
          padding-bottom: 100px;
        }
        .swiper-pagination {
          bottom: 60px;
        }
      }
      .team__image {
        margin-left: 0;
        overflow: unset;
        &:hover {
          img {
            transform: scale(1);
          }
        }
      }
      .team__content {
        max-width: 100%;
        background-color: #26343f;
        text-align: right;
        padding: 93px 60px 24px 51px;
      }
      .team__image img {
        width: 100%;
      }
      .team__title {
        font-size: 20px;
        color: var(--white);
      }
      .team__content p {
        color: var(--white);
        text-transform: capitalize;
        padding-top: 0;
      }
      .share__wrap {
        top: auto;
        left: 0;
        padding-left: 30px;
        bottom: -32px;
        z-index: 9;
      }
      .share__wrap .share-btn {
        border-radius: 0px 20px 0px 0px;
        background: var(--p-3, #d8ff36);
      }
      .share__wrap .active li {
        top: -212px;
      }
      .share__wrap li a {
        background-color: #d8ff36;
      }
    }
  }
  &__wrapper {
    &-5 {
      display: grid;
      grid-template-columns: 0.4fr 1fr;
      gap: 90px;
    }
  }
  &__subtitle-5 {
    color: var(--white);
    font-size: 18px;
    font-weight: 500;
    line-height: 1.2;
    letter-spacing: 1.8px;
    text-transform: uppercase;
    padding-bottom: 20px;
    display: block;
  }
  &__sub-5 {
    color: var(--white);
    font-size: 48px;
    font-weight: 600;
    line-height: 1; /* 100% */
    letter-spacing: 0.16px;
    text-transform: uppercase;
    @media #{$sm} {
      font-size: 30px;
    }
  }
  &__title-5 {
    color: var(--white);
    font-size: 100px;
    font-weight: 700;
    line-height: 1;
    letter-spacing: -3px;
    text-transform: uppercase;
    @media #{$lg} {
      font-size: 50px;
    }
    @media #{$sm} {
      font-size: 30px;
      letter-spacing: 0;
    }
    span {
      text-align: right;
      display: block;
      padding-right: 70px;
      @media #{$md} {
        text-align: left;
      }
    }
  }
  &__left-5 {
    position: relative;
    @media #{$md} {
      margin-bottom: 50px;
    }
    .testimonial-five-prev {
      left: 0;
      bottom: -122px;
      top: auto;
      @media #{$md} {
        left: auto;
        bottom: 0;
      }
    }
    .testimonial-five-next {
      left: 70px;
      bottom: -122px;
      top: auto;
      z-index: 3;
      @media #{$md} {
        left: auto;
        bottom: 0;
      }
    }
  }
}

/*

-----------------------
HOME FIVE PRICING 
-----------------------

*/

.pricing {
  &__area {
    &-5 {
      padding-bottom: 150px;
      @media #{$md} {
        padding-bottom: 60px;
      }
    }
  }
  &__items-5 {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 30px;
    @media #{$md} {
      grid-template-columns: 1fr 1fr;
    }
    @media #{$sm} {
      grid-template-columns: 1fr;
    }
  }
  &__item-5 {
    position: relative;
    @media #{$sm} {
      margin-bottom: 50px;
    }
    &:hover {
      .pricing__header-5 {
        background: var(--yellow-2);
        .title {
          color: var(--black);
        }
        .title-2 {
          color: var(--black);
        }
        p {
          color: var(--black);
        }
      }
    }
  }
  &__header-5 {
    background: #252525;
    padding: 40px;
    transition: 0.6s;
    @media #{$lg} {
      padding: 25px;
    }
    .title {
      color: var(--white);
      font-size: 20px;
      font-weight: 600;
      line-height: 1.2;
      margin-bottom: 10px;
    }
    .title-2 {
      color: var(--white);
      font-size: 16px;
      font-weight: 500;
      line-height: 1;
      padding-bottom: 20px;
      span {
        font-size: 48px;
        font-weight: 600;
        line-height: 1;
        letter-spacing: 0.16px;
        text-transform: uppercase;
      }
    }
    p {
      color: var(--white);
    }
  }
  &__feature-5 {
    background: #26343f;
    padding: 40px;
    padding-bottom: 60px;
  }
  &__feature-list {
    li {
      color: var(--white);
      font-size: 16px;
      font-weight: 500;
      line-height: 1;
      margin-bottom: 20px;
    }
  }
  &__btn-wrapper {
    position: absolute;
    left: 50%;
    bottom: -30px;
    transform: translateX(-50%);
  }
  &__btn {
    display: inline-block;
    width: 67px;
    height: 67px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--black);
    background: var(--primary);
    border-radius: 50px;
  }
}

/*

-----------------------
HOME FIVE TESTIMONIAL 
-----------------------

*/
.testimonial-five-active {
  padding-bottom: 40px;
  .swiper-wrapper {
    padding-bottom: 120px;
  }
}
.testimonial-five-prev {
  width: 50px;
  height: 50px;
  background: #10332f;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--white);
  position: absolute;
  right: 70px;
  top: 70px;
  z-index: 3;
  @media #{$sm} {
    display: none;
  }
  &:hover {
    background: var(--primary);
  }
}
.testimonial-five-next {
  @extend .testimonial-five-prev;
  right: 0;
  @media #{$sm} {
    font-size: 30px;
  }
}
.testimonial {
  &__area {
    &-5 {
      padding-bottom: 50px;
      position: relative;
      @media #{$md} {
        padding-bottom: 100px;
      }
    }
  }
  &__item-5 {
    max-width: 630px;
    position: relative;
    padding-left: 80px;
    @media #{$md} {
      padding-left: 36px;
    }
    &::before {
      content: "";
      position: absolute;
      left: 19px;
      right: 0;
      bottom: 0;
      top: 87px;
      height: 100%;
      width: 100%;
      border: 1px solid #333f4d;
    }
    p {
      color: var(--white);
    }
  }
  &__header-5 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
  }
  &__thumb-5 {
    margin-left: -20px;
    position: relative;
    z-index: 2;
  }
  &__rating-5 {
    align-self: flex-end;
    padding: 10px;
    transform: translateY(-16px);
  }
  &__rating-wrap-5 {
    display: flex;
  }
  &__rating-star {
    background: var(--yellow-2);
    padding: 5px 10px;
  }
  &__rate-5 {
    background: var(--primary);
    color: #0e1e2a;
    font-size: 16px;
    font-weight: 500;
    line-height: 1;
    padding: 5px 10px;
  }
  &__footer-5 {
    margin-top: 20px;
    max-width: 250px;
    margin-left: auto;
    background: var(--primary);
    padding: 15px;
    text-align: center;
    position: absolute;
    right: 80px;
  }
  &__name-5 {
    color: var(--gray-900, #010d4c);
    font-size: 20px;
    font-weight: 600;
    line-height: 1;
    padding-bottom: 8px;
  }
  &__post-5 {
    color: #333f4d;
    font-size: 16px;
    font-weight: 500;
    line-height: 1;
  }
}

/*

-----------------------
HOME FIVE FOOTER 
-----------------------

*/
.contact__form.contact__form {
  background: #26343f;
  padding: 50px;
  @media #{$lg} {
    transform: translateY(0);
  }
  @media #{$sm} {
    padding: 20px;
  }
  .errorMessage{
    color: #ffe400;
  }
  input {
    // margin-bottom: 30px;
  }
  .submit {
    border-radius: 6px;
    padding: 20px;
    background: #d8ff36;
    color: var(--black);
  }
}
.footer {
  &__area {
    &-5 {
      padding-left: 315px;
      padding-right: 80px;
      padding-top: 200px;
      .footer__address {
        @media #{$lg} {
          padding-left: 0;
        }
        @media #{$sm} {
          padding-top: 0;
        }
      }
      @media #{$xxl} {
        padding-left: 100px;
      }
      @media #{$lg} {
        padding-top: 0;
      }
      @media #{$md} {
        padding-left: 50px;
      }
      @media #{$sm} {
        padding-left: 15px;
        padding-right: 15px;
      }
      p {
        color: var(--white);
      }
    }
  }
  &__wrapper-5 {
    display: grid;
    grid-template-columns: 1fr 0.5fr;
    @media #{$lg} {
      grid-template-columns: 1fr;
    }
  }
  &__form-title-5 {
    color: var(--white);
    font-size: 48px;
    font-weight: 600;
    line-height: 1;
    letter-spacing: 0.16px;
    text-transform: uppercase;
    margin-bottom: 40px;
    @media #{$xxl} {
      font-size: 30px;
    }
  }
  &__field-5 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 30px;
    @media #{$sm} {
      grid-template-columns: 1fr;
      gap: 0;
    }
  }
  &__section-wrapper-5 {
    margin-bottom: 130px;
    @media #{$sm} {
      margin-bottom: 50px;
    }
  }
  &__title-5 {
    font-size: 100px;
    font-weight: 700;
    line-height: 1;
    letter-spacing: -3px;
    text-transform: uppercase;
    -webkit-text-stroke: 1px white;
    -webkit-text-fill-color: #0e1e2a;
    @media #{$xxl} {
      font-size: 70px;
    }
    @media #{$md} {
      font-size: 40px;
    }
  }
}
